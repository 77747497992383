<template>
  <div class="page-editor editor-wrapper" v-loading="loading">
    <!--左侧导航-->
    <div class="editor-side-bar border-R">
      <el-tabs tab-position="left" v-model="activeSideBar" style="height: 100%;">
        <el-tab-pane v-for="(item, index) in sidebarMenus" :key="index" :name="item.value">
          <el-tooltip slot="label" class="item" effect="dark" :content="item.label" placement="right">
            <i :class="item.elementUiIcon"></i>
          </el-tooltip>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--组件&页面&模板-->
    <div class="editor-page-edit-wrapper">
      <componentLibs v-if="activeSideBar === 'componentLibs'"/>
      <pageManage v-if="activeSideBar === 'pageManage'"/>
      <templateLibs v-if="activeSideBar === 'templateLibs'"/>
    </div>
    <!--页面编辑区域-->
    <div class="editor-main">
      <div class="control-bar-wrapper">
        <controlBar
          :scale.sync="canvasConfig.scale"
          @import-psd-data="importPsdData"
          @showPreview="showPreviewFn"
          @cancel="cancelFn"
          @publish="publishFn"
          @save="saveFn"/>
      </div>
      <editorPan :scale.sync="canvasConfig.scale"/>
    </div>
    <!--属性编辑区域-->
    <div class="el-attr-edit-wrapper scrollbar-wrapper">
      <el-tabs v-model="activeAttr" stretch>
        <el-tab-pane label="属性" name="属性">
          <attrEdit></attrEdit>
        </el-tab-pane>
        <el-tab-pane label="事件" name="事件">
          <eventEdit></eventEdit>
        </el-tab-pane>
        <el-tab-pane label="动画" name="动画">
          <animationEdit></animationEdit>
        </el-tab-pane>
        <el-tab-pane label="JS脚本" name="脚本">
          <scriptEdit></scriptEdit>
        </el-tab-pane>
        <el-tab-pane label="页面设置" name="页面属性">
          <pageAttrEdit></pageAttrEdit>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--预览-->
    <previewPage
      v-if="showPreview"
      :pageData="projectData"
      :pageId="id"
      @closePreview="showPreview = false"
      @publishFn="publishFn"
      @saveFn="saveFn"></previewPage>
    <!--我的图片-->

    <imageLibs/>
  </div>
</template>

<script>
import componentLibs from './components/component-libs/Index'
import pageManage from './components/page-manage'
import templateLibs from './components/template-libs'
import editorPan from './components/editor-panel/Index'
// 属性编辑相关组件
import attrEdit from './components/attr-configure/attr-edit'
import animationEdit from './components/attr-configure/animation-edit'
import eventEdit from './components/attr-configure/event-edit'
import pageAttrEdit from './components/attr-configure/page-attr-edit'
import scriptEdit from './components/attr-configure/script-edit'

import controlBar from './components/control-bar'

import previewPage from './components/preview'
import imageLibs from '@/template-editor/client/components/image-libs'

import {mapState} from 'vuex'
import html2canvas from 'html2canvas'
import {
  createCompanyTemplate,
  getCompanyTemplateInfo,
  getTemplateInfo,
  updateCompanyTemplate
} from "@/api/card_order/order";

export default {
  components: {
    componentLibs,
    pageManage,
    templateLibs,
    imageLibs,
    editorPan,
    attrEdit,
    animationEdit,
    eventEdit,
    pageAttrEdit,
    scriptEdit,
    controlBar,
    previewPage
  },
  data() {
    return {
      view_type: 1,
		local_id:0,
      id: '', // 当前页面id
      loading: false,
      fuli_type: '',
      showPreview: false,
      activeAttr: '属性',
      activeSideBar: 'componentLibs',
      sidebarMenus: [
        {
          label: '组件列表',
          value: 'componentLibs',
          elementUiIcon: 'el-icon-s-operation'
        },
        {
          label: '页面管理',
          value: 'pageManage',
          elementUiIcon: 'el-icon-document'
        },
        // {
        // 	label: '模板库',
        // 	value: 'templateLibs',
        // 	elementUiIcon: 'el-icon-files'
        // }
      ],
      canvasConfig: {
        scale: 1
      }
    }
  },
  computed: {
    ...mapState({
      projectData: state => state.editor.projectData,
      activePageUUID: state => state.editor.activePageUUID,
      activeElementUUID: state => state.editor.activeElementUUID
    })
  },
  created() {
    this.$store.dispatch('setPrjectData')
    this.id = this.$route.query.id;
	  this.local_id = this.$route.query.local_id;
    this.view_type = this.$route.query.view_type;

    this.initPageData();
  },
  methods: {
    backWelfare(type, id) {
      let path = this.$route.query.url
      if (path) {
        this.$router.push(path)
        return
      }
      switch (type) {
        case 1:
          //取消
			this.$router.push({path: `/card/pointManage/pointOrderAdd?local_id=${this.local_id}`})
		break
          //this.$router.go(-1)
          // this.$router.push({path: '/welfare-manage/new-care'})
          //break
        case 2:
			this.$router.push({path: `/card/pointManage/pointOrderAdd?local_id=${this.local_id}&new_id=${id}`})
          break
      }
    },
    /**
     * 初始化页面数据
     */
    async initPageData() {
      this.loading = true
      let result = this.view_type == 1 ?
        await getTemplateInfo({id: this.id}) :
        await getCompanyTemplateInfo({id: this.id})
      console.log('初始化页面数据',result);
      this.loading = false
      this.fuli_type = result.fuli_type_id
      await this.$store.dispatch('setPrjectData', JSON.parse(result.template_h5_datas));
    },

    /**
     * 保存
     */
    async saveFn() {
      // await this.screenshots()
      // 提交数据再预览
      await updateCompanyTemplate({
        template_h5_datas: JSON.stringify(this.projectData),
        id: this.id
      })
      this.$message.success('保存成功!')
      this.backWelfare(2, this.id);
    },
    /**
     * 保存
     */
    async publishFn() {
      // 提交数据再预览
      let data = {...this.projectData};
      data.isPublish = true;
      await updateCompanyTemplate({pageData: data, id: this.id})
      this.$message.success('已成功保存并发布!');
      this.showPreview = false
      await this.$router.push({name: 'pageList'})
    },
    async showPreviewFn() {

      // await this.screenshots()
      // 提交数据再预览
      if (this.view_type == 1) {
        let result = await createCompanyTemplate(
          {
            template_h5_datas: JSON.stringify(this.projectData),
            fuli_type_id: this.fuli_type,
            template_name: this.projectData.title,
            template_img: this.projectData.coverImage,
            id: this.id
          }
        )
        this.id = result.id
        this.view_type = "2"
        this.showPreview = true

      } else {
        if (this.id != 0) {
          await updateCompanyTemplate({
            template_h5_datas: JSON.stringify(this.projectData),
            id: this.id
          })
          this.showPreview = true
        } else {
          let result = await createCompanyTemplate(
            {
              template_h5_datas: JSON.stringify(this.projectData),
              fuli_type_id: this.fuli_type,
              template_name: this.projectData.title,
              template_img: 'http://dev.platform.eqiai.net/static/templateH5/static/img/quark--pagecover-image.5a0dbec0.png',
              id: this.id
            }
          )
          this.id = result.id
          this.view_type = "2"
          this.showPreview = true
        }
      }
    },
    /**
     * 退出
     */
    cancelFn() {
      this.$confirm('确认退出编辑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.backWelfare(1, this.id);
      }).catch(() => {
      })
    },
    /**
     * 提供截屏作为项目主图
     */
    screenshots() {
      const el = document.querySelector("#canvas-panel")
      return new Promise((resolve, reject) => {
        html2canvas(el, {proxy: `${this.$config.baseURL}/common/html2canvas/corsproxy`}).then(canvas => {
          const dataUrl = canvas.toDataURL('image/jpeg', 0.6)
          const blob = this.$mUtils.dataURItoBlob(dataUrl)
          const file = new window.File([blob], +new Date() + '.png', {type: 'image/png'})
          let params = new FormData()
          params.append('file', file);
          this.$axios.post('/common/uploadFile', params).then(res => {
            // 替换主图链接
            this.projectData.coverImage = res.body;
            resolve(res.body)
          }).catch(err => {
            reject(err)
          })
        })
      })
    },
    /**
     *
     * @param psdData
     */
    importPsdData(psdData) {
      let elementsList = psdData.elements
      let psdWidth = psdData.document.width;
      let scalingRatio = this.projectData.width / psdWidth
      elementsList.forEach(item => {
        let {width, height, top, left, imageSrc, opacity, zIndex} = item;
        setTimeout(() => {
          this.$store.dispatch('addElement', {
            elName: 'qk-image',
            defaultStyle: {
              width: width * scalingRatio,
              height: height * scalingRatio,
              top: top * scalingRatio,
              left: left * scalingRatio,
              zIndex: zIndex,
              opacity
            },
            needProps: {
              imageSrc: imageSrc
            }
          })
        }, 10)
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.editor-wrapper {
  display: flex;
  height: 100%;
  position: relative;

  .editor-side-bar {
    width: 55px;
  }

  .editor-page-edit-wrapper {
    width: 210px;
    padding: 0 1px;
  }

  .editor-main {
    flex: 1;
    background: #f0f2f5;
    position: relative;
  }

  .el-attr-edit-wrapper {
    width: 380px;
    padding: 0;
  }
}

.control-bar-wrapper {
  position: absolute;
  top: -44px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
}
</style>
<style lang="scss">
.editor-side-bar {
  .el-tabs__item.is-active {
    background: rgba(37, 165, 137, 0.09);
  }
}

.el-attr-edit-wrapper {
  .el-tabs {
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
  }

  .el-tabs__content {
    height: calc(100% - 55px);

    & > div {
      height: 100%;
    }

    .attr-edit-inner {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
</style>
