<template>
  <div>
    <p class="page-title text-center">模板库</p>
    <p class="gray text-center paddingT20">待完善...</p>

  </div>
</template>

<script>
	export default {
		name: "template-libs"
	}
</script>

<style scoped>

</style>
